import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";

const BlogPost = () => {
  const blogUrl = "https://selbysellssd.com/tips-buying-a-home-during-a-recession";

  return (
    <LayoutWrapper
      title="8 Tips for Buying a Home During a Recession | The Selby Team"
      desc="An economic recession doesn’t have to stand in the way of your dreams. Here are eight helpful tips for buying a home during a recession."
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              Top Tips for Buying a Home During a Recession
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By The Selby Team
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  November 20, 2022
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={blogUrl} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_Tips for Buying a Home During a Recession.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                An economic recession doesn’t have to stand in the way of your dreams. So here are
                eight helpful tips for buying a home during a recession. nulla.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                1. Set a Reasonable Budget
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Start by determining how much house you can realistically afford. This step is
                essential in any economic climate, but you and your family may need to maintain an
                emergency fund to cover unexpected costs during a recession.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                2. Consider Your Lending Options
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                First-time home buyers might consider lending options that make their homes more
                affordable. For instance, loans backed by the{" "}
                <a href="https://www.hud.gov/program_offices/housing/fhahistory" target="_Blank">
                  Federal Housing Administration (FHA)
                </a>{" "}
                require a down payment as low as 3%, and qualifying veterans can obtain a{" "}
                <a href="https://www.va.gov/housing-assistance/home-loans/" target="_Blank">
                  VA loan
                </a>{" "}
                with no money down.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                One of the best tips for buying a home during a recession is to seek out a loan
                program that makes it easier to make your purchase.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                3. Shop Around for a Lender
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Always compare rates and reviews from at least three different mortgage lenders.
                Then, you can compare rates and loan products to find a lender matching your
                financial needs.{" "}
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Talk to your friends and family as well. They may have worked with a lender in the
                past and be in a position to offer a solid recommendation.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                4. Seek Out Motivated Sellers
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Look for sellers who are motivated to sell. The longer a house is on the market, the
                more likely the seller will bargain with you.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                You may secure a better deal by purchasing a home from someone in a hurry to sell.
                And if the house is presently empty, it may indicate that the seller has already
                moved and is making two mortgage payments.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                5. Get Pre-Approved
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                While a pre-approval letter doesn’t guarantee your final price, it does indicate
                that you’ve submitted your financial documents and are qualified to obtain a
                mortgage of a certain amount. This can help in a bidding war since your pre-approval
                letter gives you greater legitimacy in the seller’s eyes.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                6. Make Sure the Title Is Clear
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Property owners can easily fall behind in their payments during a recession, meaning
                the home may have a lien from a bank or service provider. Therefore, before you
                commit to buying, you may need a lawyer to perform a title search to ensure you can
                take ownership of the property.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                7. Work with a Local Real Estate Agent
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Local realtors can direct your attention to homes you weren’t even aware of. And
                because they know the local community, your real estate agent can help you better
                understand the pros and cons of each property and make the right choice for you and
                your family.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                8. Negotiate
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                A recession is a buyer’s market. That means you have more power to negotiate your
                final price. If you are against a seller who won’t budge, don’t be afraid to walk
                away and look for a better deal elsewhere.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Find the Home of Your Dreams
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                These tips for buying a home during a recession can help you make your dream home a
                reality, regardless of market conditions. Contact The Selby Team to get started on
                finding your new home.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={blogUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
